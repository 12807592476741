import { Button, Flex, Heading, Text } from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import Image from 'next/image'
import styled, { keyframes } from 'styled-components'
import badgeDarkImage from '../../../../public/images/home/lunar-bunny/Dex-Badge-Dark.png'
import badgeLightImage from '../../../../public/images/home/lunar-bunny/Dex-Badge-Light.png'


const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`


const ResponsiveText = styled(Text)`
  width: 100%;  // Default width for mobile devices
  margin-bottom: 24px;

  @media (min-width: 768px) {
    width: 80%;  // Width for desktop devices
  }
`;

const BunnyWrapper = styled.div`
  width: 100%;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const Hero = () => {
  const { t } = useTranslation()
  const { address } = useAccount()
  const { theme, isDark } = useTheme()


  return (
    <>
      {/* <BgWrapper>
        <InnerWrapper>{theme.isDark ? <SlideSvgDark width="100%" /> : <SlideSvgLight width="100%" />}</InnerWrapper>
      </BgWrapper> */}
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['center', null, null, 'center']}
        justifyContent="center"
        mt={[null, null, 0]}
        id="homepage-hero"
      >
        <Flex flex="1" flexDirection="column">
          <Heading scale="xxl" mb="24px" mt="50px">
            Welcome DeFi  <br />On <span style={{color  : "#f1b90a"}}>DANDex</span>
          </Heading>
          <ResponsiveText color={isDark ? '#BABABA' : ''}>
            All in one decentralized exchange for leveraging funds swap, add pools, earn, and build your project on the Binance chain.
          </ResponsiveText>
          <Flex>
            {!address && <ConnectWalletButton mr="8px" />}
            <NextLinkFromReactRouter to="/swap">
              <Button variant={`${address ? 'primary' : isDark ? 'secondary' : 'tertiary'}`}>{t('Trade Now')}</Button>
            </NextLinkFromReactRouter>
          </Flex>
        </Flex>
        {/* <Flex>
          <IFrame width='560' height='315' src='https://defillama.com/chart/protocol/stealthpad?&theme=dark' />
        </Flex> */}
        <Flex
          height={['192px', null, null, '100%']}
          width={['192px', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
          position="relative"
        >
          <BunnyWrapper>
            {isDark ? (
              <Image src={badgeDarkImage} priority alt='badgeLightImage' />
            ) : (
              <Image src={badgeLightImage} priority alt='badgeLightImage' />
            )}

          </BunnyWrapper>

        </Flex>
      </Flex>
    </>
  )
}

export default Hero
