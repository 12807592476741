import { Text, Card, CardBody, useMatchBreakpoints } from '@pancakeswap/uikit'
import styled from 'styled-components';

const StyledCard = styled(Card) <{ background: string; rotation?: string }>`
  height: fit-content;
  padding: 0px;
  box-sizing: border-box;
  /* background:${({ theme }) => theme.colors.backgroundAlt2}; */
  background: #14293D;
  border-radius: 15px;
  border: 1px solid #363636;
  ${({ theme }) => theme.mediaQueries.md} {
    ${({ rotation }) => (rotation ? `transform: rotate(${rotation});` : '')}
  }
`

const StyledCardBody = styled(CardBody) <{ background: string; rotation?: string }>`
  background: ${({ theme }) => theme.colors.backgroundAlt2};
`

const ExchangeStatics: React.FC<{ headingText: any; bodyText: string; highlightColor: string }> = ({
  headingText,
  bodyText
}) => {

  const { isMobile } = useMatchBreakpoints();

  return (
    <StyledCard style={{ marginTop: isMobile ? '25px' : "0px" }}>
      <StyledCardBody>
        <Text fontSize="25px" fontWeight="800" style={{ marginBottom: "5px" }}>
          {(headingText)}
        </Text>
        <Text fontSize="15px" color="textSubtle">{bodyText}</Text>
      </StyledCardBody>
    </StyledCard>
  )
}

export default ExchangeStatics
